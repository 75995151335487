import TYPES from '@/types';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import { SearchAllianzCustomerAvailableBalanceQuery } from '@/modules/my-investment/allianz/allianz-customer-available-balance/application/queries';

// Domain
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AccountBalanceViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_QUERY)
  private readonly search_available_balance_query!: SearchAllianzCustomerAvailableBalanceQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.account_balance';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  is_loading = true;

  total_value = 0;

  available_balance = 0;

  date_of_issue = '';

  allianz_account: AllianzAccountEntity = new State().account;

  show_withdrawals_dialog = false;

  show_deposits_dialog = false;

  modal_calendly_open = false;

  has_active_recurrent_contribution = false;

  show_recurrent_contribution_dialog= false;

  show_edit_recurring_contribution_dialog = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get allianz_account_amount() {
    // eslint-disable-next-line max-len
    return (this.allianz_account.amount) ? this.getFormattedBalance(this.allianz_account.amount) : this.getFormattedBalance(0);
  }

  get class_to_recurrent_contribution() {
    return (this.allianz_account.home_desire) ? 'text-decoration-underline cursor-pointer' : '';
  }

  getFormattedBalance = (amount: number) => (currencyFormat(amount));

  addRecurringContributionInformationUpdatedEventListener = () => {
    window.addEventListener('allianz.update.recurring.contribution', this.loadAllianzAccount);
  }

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (this.allianz_account) {
        this.date_of_issue = this.date_formatter.formatDate(this.allianz_account.created_at || new Date(), 'DD MMM YYYY');
        await this.loadPosition();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  loadPosition = async () => {
    try {
      const positions = await this.search_allianz_account_report_query.execute(this.customer_id);
      if (positions.length) {
        this.total_value = positions.reduce(
          (total, current) => total + current.final_balance_mxn!, 0,
        );
        this.available_balance = 0;
        positions.map(async (position) => {
          if (position.found_code) {
            const available_balance_by_fund = await this.loadAvailableBalance(position.found_code);
            this.available_balance += available_balance_by_fund;
          }
        });
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  loadAvailableBalance = async (fund_code: string): Promise<number> => {
    try {
      const customer_available_balance = await this.search_available_balance_query
        .execute(fund_code);
      return customer_available_balance.available_balance;
    } catch {
      return 0;
    }
  }

  requestWithdrawal = () => {
    this.show_withdrawals_dialog = true;
  }

  requestDeposit = () => {
    this.show_deposits_dialog = true;
  }

  showCalendlyModal = () => {
    this.modal_calendly_open = true;
  }

  showRecurrentContributionDialog = () => {
    if (this.allianz_account.home_desire) this.show_recurrent_contribution_dialog = true;
  }

  showEditRecurringContribution = () => {
    this.show_edit_recurring_contribution_dialog = true;
    this.show_recurrent_contribution_dialog = false;
  }

  closeEditRecurringContribution = () => {
    this.show_edit_recurring_contribution_dialog = false;
  }

  initialize = async () => {
    await this.loadAllianzAccount();
    this.addRecurringContributionInformationUpdatedEventListener();
    this.is_loading = false;
  }
}
